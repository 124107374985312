/* eslint-disable no-unused-vars */
const STABLE_API_ROOT = "/api/v1";
const APP_VERSION = "2.4";
import { data } from "jquery";
import queryString from "query-string";
export default {
  botMessage: `${STABLE_API_ROOT}/bot/message`,
  assets: {
    doctorPlaceHolder: "/assets/images/doctor_placeholder.jpg",
  },
  fetchPayments: (userId, accessToken) =>
    `${STABLE_API_ROOT}/users/${userId}/payments?accessToken=${accessToken}`,
  getToken: (sessionId, type, accessToken) =>
    `${STABLE_API_ROOT}/appointments/getToken/${sessionId}/${type}?accessToken=${accessToken}`,
  faqs: `${STABLE_API_ROOT}/faqs`,
  faq: (token, id) => `${STABLE_API_ROOT}/faqs/${id}?accessToken=${token}`,
  getFAQ: (id) => `${STABLE_API_ROOT}/faqs/${id}`,
  faqFindByCategory: (category) =>
    `${STABLE_API_ROOT}/faqs/category/${category}`,
  faqFindBySlug: (category) => `${STABLE_API_ROOT}/faqs/slug/${category}`,
  faqSearch: (term) => `${STABLE_API_ROOT}/faqs/search/${term}`,
  createFaqWithToken: (token, id) =>
    `${STABLE_API_ROOT}/faqs?accessToken=${token}`,
  companies: `${STABLE_API_ROOT}/companies`,
  createCompanyWithToken: (token) =>
    `${STABLE_API_ROOT}/companies?accessToken=${token}`,
  companyWithToken: (token, id) =>
    `${STABLE_API_ROOT}/companies/${id}?accessToken=${token}`,
  nurses: `${STABLE_API_ROOT}/users/nurses`,
  getNurse: (token, id) =>
    `${STABLE_API_ROOT}/users/getNurse/${id}?accessToken=${token}`,
  nursesWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/nurses/${id}?accessToken=${token}`,
  createNurseWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/nurses?accessToken=${token}`,
  companyAdmin: `${STABLE_API_ROOT}/users/companyAdmins`,
  getCompanyAdmin: (token, id) =>
    `${STABLE_API_ROOT}/users/companyAdmins/${id}?accessToken=${token}`,
  companyAdminWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/companyAdmins/${id}?accessToken=${token}`,
  createCompanyAdminWithToken: (token, id) =>
    `${STABLE_API_ROOT}/users/companyAdmins?accessToken=${token}`,
  createCompanyAdmin: (token) =>
    `${STABLE_API_ROOT}/users/companyAdmins/?accessToken=${token}`,
  companyLogo: (image) =>
    `${STABLE_API_ROOT}/containers/docto-company-logos/download/${encodeURIComponent(
      image
    )}`,
  askBot: `${STABLE_API_ROOT}/services/bot/ask`,
  uploadCompanyLogo: (token) =>
    `${STABLE_API_ROOT}/containers/docto-company-logos/upload?accessToken=${token}`,
  uploadProfilePicture: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/uploadProfilePicture?accessToken=${token}`,
  uploadESingature: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/upload-esign?accessToken=${token}`,
  uploadReferralDocument: (token) =>
    `${STABLE_API_ROOT}/containers/docto-referral-documents/upload?accessToken=${token}`,
  uploadTempFiles: () => `${STABLE_API_ROOT}/fileUploads/upload-temp-files`,
  uploadAppointmentReferralDocument: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/${appointmentId}/upload-referral?accessToken=${token}`,
  uploadAppointmentReferralPublic: () =>
    `${STABLE_API_ROOT}/fileUploads/upload-public-referral`,
  uploadAppointmentPatientReferralDocument: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/${appointmentId}/upload-referral-patient?accessToken=${token}`,
  uploadHeroImage: (token, specialtyID) =>
    `${STABLE_API_ROOT}/specialties/${specialtyID}/upload-hero?accessToken=${token}`,
  uploadMasterFolder: (userId, token) =>
    `${STABLE_API_ROOT}/fileUploads/${userId}/upload-master?accessToken=${token}`,
  profilePicUrl: (image) =>
    `https://docto-profile-pictures.s3-ap-southeast-2.amazonaws.com/${encodeURIComponent(
      image
    )}`,
  thumbnailProfilePicUrl: (image) =>
    `https://d3v3oeitkas7cn.cloudfront.net/${encodeURIComponent(image)}`,
  chatFileUrl: (doc) =>
    `${STABLE_API_ROOT}/containers/docto-chat-documents/download/${encodeURIComponent(
      doc
    )}`,
  faxUrl: (bucket, fax) =>
    `${STABLE_API_ROOT}/containers/${bucket}/download/${fax}`,
  createDoctor: (token) =>
    `${STABLE_API_ROOT}/users/doctors/?accessToken=${token}`,
  updateDoctor: (doctorId, token) =>
    `${STABLE_API_ROOT}/users/doctors/${doctorId}?accessToken=${token}`,
  downloadReferral: (doc) =>
    `${STABLE_API_ROOT}/containers/docto-referral-documents/download/${encodeURIComponent(
      doc
    )}`,
  downloadReferralDocument: (appointmentId, fileName) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral/${appointmentId}/${encodeURIComponent(
      fileName
    )}`,
  downloadRelativeUrl: (relativeUrl) => `${STABLE_API_ROOT}${relativeUrl}`,
  createPatient: `${STABLE_API_ROOT}/users/createPatient`,
  sendConfirmationSignUp: (email) =>
    `${STABLE_API_ROOT}/emails/sendConfirmationSignUp/${email}`,
  sendBotRequest: () => `${STABLE_API_ROOT}/emails/sendBotRequest`,

  downloadReferralPatient: (appointmentId, fileName) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral-patient/${appointmentId}/${fileName}`,
  email: `${STABLE_API_ROOT}/emails`,
  downloadMasterFolder: (appointmentId, key) =>
    `${STABLE_API_ROOT}/fileUploads/download-master/${appointmentId}/${encodeURIComponent(
      key
    )}`,
  downloadReferralRequest: (key, token) =>
    `${STABLE_API_ROOT}/fileUploads/download-referral-request/${key}?accessToken=${token}`,
  downloadReceivedFax: (key, token) =>
    `${STABLE_API_ROOT}/containers/docto-received-fax/download/${key}?accessToken=${token}`,
  downloadDoctoTwilioCallRecording: (key, token) =>
    `${STABLE_API_ROOT}/containers/docto-twillio-call-recording/download/${key}?accessToken=${token}`,
  getDoctors: `${STABLE_API_ROOT}/users/getDoctors`,
  getEmergencyDoctors: (token) =>
    `${STABLE_API_ROOT}/users/getEmergencyDoctors?accessToken=${token}`,
  getAdminSpecialists: (token) =>
    `${STABLE_API_ROOT}/users/getAdminSpecialists?accessToken=${token}`,
  getPatients: (params) =>
    `${STABLE_API_ROOT}/users/getPatients?${queryString.stringify(params)}`,
  getEmergencyDoctor: `${STABLE_API_ROOT}/emergencyShifts/getEmergencyDoctor`,
  getEmergencyShifts: `${STABLE_API_ROOT}/emergencyShifts/getEmergencyShifts`,
  getShifts: `${STABLE_API_ROOT}/shifts`,
  getUser: (userId) => `${STABLE_API_ROOT}/users/${userId}`,
  getUserByEmail: (email) => `${STABLE_API_ROOT}/users/getUserByEmail/${email}`,
  login: `${STABLE_API_ROOT}/users/login?include=user`,
  getMessages: (token, role, page) =>
    `${STABLE_API_ROOT}/appointments/messages/${role}/${page}?accessToken=${token}`,
  getAppointmentWithMessages: (appointmentId, token, page) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/conversation-messages?accessToken=${token}&page=${page}`,
  generateAppointmentDocument: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/generateDocument/${appointmentId}?accessToken=${token}`,
  paths: {
    root: "/",
    about: "/about",
    account: {
      index: "/account",
      profile: "/account/profile",
      appointments: "/account/appointments",
      invoices: "/account/invoices",
      paymentHistory: "/account/payment-history",
      billing: "/account/billing",
      adminProfile: "/admin/patient/profile",
      adminBilling: "/admin/patient/billing",
    },
    doctor_profile: "/account/doctor/profile",
    admin: "/admin",
    appointments: "/appointments",
    browseHospital: "/browse-hospital",
    businessPage: "/business",
    contact: "/contact",
    executiveTeam: "/executive-team",
    faq: "/faq",
    forgotPassword: `/request-password`,
    login: "login",
    loginFromSignup: `/?fromSignup=true`,
    logout: `/users/sign_out`,
    messages: "/messages",
    patientInfo: "/patient-info",
    press: "/press",
    pricing: "/pricing",
    privacyPolicy: "privacy-policy",
    referAPatient: "/refer-patient",
    expertOpinion: "/expert-opinion",
    seeASpecialist: "see-a-specialist",
    securePortal: "/secure-portal",
    signup: `/signup`,
    specialists: "/doctors",
    startChat: "/?startChat=true",
    consultDrNow: "/online-doctor",
  },
  referral: `${STABLE_API_ROOT}/referrals`,
  referralWithToken: (token) =>
    `${STABLE_API_ROOT}/referrals/?accessToken=${token}`,
  getNotifications: (patientId, token) =>
    `${STABLE_API_ROOT}/users/${patientId}/notifications?access_token=${token}`,

  specialties: `${STABLE_API_ROOT}/specialties`,
  specialtiesWithToken: (token) =>
    `${STABLE_API_ROOT}/specialties?access_token=${token}`,
  specialtyWithToken: (token, specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}?access_token=${token}`,
  destroySpecialty: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}/destroy?access_token=${token}`,
  setSpecialtyPublic: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/publicUrl/${specialtyId}?access_token=${token}`,
  setSpecialtySlug: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/slug/${specialtyId}?access_token=${token}`,
  updateSpecialtyCMS: (specialtyId, token) =>
    `${STABLE_API_ROOT}/specialties/cms/${specialtyId}?access_token=${token}`,
  updateEmergencyDoctor: (token) =>
    `${STABLE_API_ROOT}/emergencyShifts?accessToken=${token}`,
  userWithToken: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}?access_token=${token}`,
  softDeleteUser: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/softDelete?access_token=${token}`,
  blockUser: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/block?access_token=${token}`,
  getPatientAccessToken: (userId, token) =>
    `${STABLE_API_ROOT}/users/maskUser/${userId}?access_token=${token}`,
  userMethodWithToken: (userId, token, method) =>
    `${STABLE_API_ROOT}/users/${userId}/${method}?access_token=${token}`,
  appointmentsWithToken: (token) =>
    `${STABLE_API_ROOT}/appointments?access_token=${token}`,
  getAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}?access_token=${token}`,
  updateAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/updateAppointment?access_token=${token}`,
  cancelAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/cancel?access_token=${token}`,
  fetchSubscriptionPlans: (userId) =>
    `${STABLE_API_ROOT}/users/${userId}/plans`,
  verifyMail: (mail) => `${STABLE_API_ROOT}/users/emails/valid/${mail}`,
  validateDoctoEmail: (mail) =>
    `${STABLE_API_ROOT}/users/emails/valid/docto/${mail}`,

  checkIn: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/checkIn/${appointmentId}?access_token=${token}`,

  preApprovedDates: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/preApprovedDates/${appointmentId}?access_token=${token}`,
  getSlackEmergUsers: (token) =>
    `${STABLE_API_ROOT}/users/slackEmergUsers?access_token=${token}`,
  removeReferralFile: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/delete-referral-document/${appointmentId}?access_token=${token}`,
  deleteReferralPatientDocument: (appointmentId, referralFileName, token) =>
    `${STABLE_API_ROOT}/fileUploads/delete-referral-document-patient/${appointmentId}/${encodeURIComponent(
      referralFileName
    )}?access_token=${token}`,

  isMedicareElegible: (long, lat) =>
    `${STABLE_API_ROOT}/users/medicare/${long}/${lat}`,
  isMedicareElegibleWithPostalCode: (postCode) =>
    `${STABLE_API_ROOT}/users/medicare/postalCode/${postCode}`,

  savePaymentMethod: (userId, token) =>
    `${STABLE_API_ROOT}/users/${userId}/savePaymentMethod?access_token=${token}`,
  acceptAppointmentGuest: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/acceptGuest`,
  acceptAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/accept?access_token=${token}`,
  sendEmergencyCallReminder: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/sendEmergencyCallReminder?access_token=${token}`,
  getNearbyPharmacy: () => `${STABLE_API_ROOT}/twilioCalls/nearbyPharmacy`,
  findPharmacy: () => `${STABLE_API_ROOT}/twilioCalls/findPharmacy`,
  fetchEmergencyTwilioCall: (token) =>
    `${STABLE_API_ROOT}/twilioCalls/fetchEmergencyCalls?accessToken=${token}`,
  sendMagicLink: (email) => `${STABLE_API_ROOT}/emails/sendMagicLink/${email}`,
  sendMagicVerificationLink: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/sendPinCodeWithMail`,
  getDetailsAppointments: (token, role, doctor, search) =>
    `${STABLE_API_ROOT}/appointments/details/${role}/${doctor}?search=${search}&access_token=${token}`,
  getAppointments: (token, type, role, page, search) =>
    `${STABLE_API_ROOT}/appointments/list/${type}/${role}/${page}?search=${search}&access_token=${token}`,
  createFreeScheduledAppointment: (token) =>
    `${STABLE_API_ROOT}/appointments/createFreeScheduledAppointment?access_token=${token}`,
  updateFreeScheduledAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/updateFreeScheduledAppointment/${appointmentId}?access_token=${token}`,
  destroyAppointmentById: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/destroy?access_token=${token}`,
  createAppointmentRequest: (token) =>
    `${STABLE_API_ROOT}/appointments/createAppointmentRequest?access_token=${token}`,
  getAdminAppointments: (type, filters) =>
    `${STABLE_API_ROOT}/appointments/admin/${type}?${filters}`,
  getAdminAppointmentSheets: (format) =>
    `${STABLE_API_ROOT}/appointments/admin/sheets/${format}`,
  getNurseAppointmentSheets: (format) =>
    `${STABLE_API_ROOT}/appointments/nurse/${format}`,
  getCompanyAdminAppointmentSheets: (format) =>
    `${STABLE_API_ROOT}/appointments/companyAdmin/${format}`,
  fetchSpecialties: () => `${STABLE_API_ROOT}/specialties/public`,
  sendBotActionEmail: () => `${STABLE_API_ROOT}/emails/sendBotActionEmail`,
  fetchbrowseHospitalPage: `${STABLE_API_ROOT}/specialties/fetchbrowseHospitalPage`,
  fetchSpecialistsBySpecialtyId: (specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}/users/public`,
  getSpecialtyInfoForUser: (specialtyId, userId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}/specialtyInfoForUser/${userId}`,
  fetchSpecialtyById: (specialtyId) =>
    `${STABLE_API_ROOT}/specialties/${specialtyId}`,
  fetchSpecialtyByName: (specialtyId) =>
    `${STABLE_API_ROOT}/specialties/name/${specialtyId}`,
  fetchSpecialtyBySlug: (specialtySlug) =>
    `${STABLE_API_ROOT}/specialties/slug/${specialtySlug}`,
  listReferralDocuments: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-referrals-files/${appointmentId}?access_token=${token}`,
  listPatientReferralDocuments: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-referrals-files-patient/${appointmentId}?access_token=${token}`,
  listPatientDocumentsByAppointmentId: (appointmentId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-patient-documents/${appointmentId}?access_token=${token}`,
  listReferralDocumentsByUserId: (userId, token) =>
    `${STABLE_API_ROOT}/fileUploads/list-user-referrals-files/${userId}?access_token=${token}`,
  listPaginatedCallRecordings: (token) =>
    `${STABLE_API_ROOT}/fileUploads/list-paginated-call-recordings/?access_token=${token}`,
  updateReceivedFaxes: (token) =>
    `${STABLE_API_ROOT}/ReceivedFaxes/update?access_token=${token}`,
  listPaginatedReceivedFaxes: (page, perPage, allocatedOnly, token) =>
    `${STABLE_API_ROOT}/ReceivedFaxes/paginated/${page}/${perPage}/${allocatedOnly}?access_token=${token}`,

  moveFaxtoAppointment: (fileName, userId, token) =>
    `${STABLE_API_ROOT}/ReceivedFaxes/move/${fileName}/${userId}?access_token=${token}`,
  deleteFax: (fileName, token) =>
    `${STABLE_API_ROOT}/ReceivedFaxes/delete/${fileName}?access_token=${token}`,
  changeConsultationCost: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/changePrice?access_token=${token}`,
  getAppointmentToken: (token) =>
    `${STABLE_API_ROOT}/appointments/appointmentToken?access_token=${token}`,
  validateEmergencyAppointmentAccess: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/emergencyAppointment/${appointmentId}/access?access_token=${token}`,
  sendSmsCode: () => `${STABLE_API_ROOT}/verificationPhoneCodes/sendPinCode`,
  verifyPinCode: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/verifyPinCode`,
  loginWithPinCode: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/loginWithPinCode`,
  createPatientWithFreeEmergencyAccess: () =>
    `${STABLE_API_ROOT}/users/createPatientWithFreeEmergencyAccess`,
  fetchDoctorNotesByAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/appointments/${appointmentId}?access_token=${token}`,
  fetchDoctorNotesByPatientId: (patientId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/patient/${patientId}?access_token=${token}`,
  fetchFullDoctorNotesByAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/appointments/${appointmentId}/notes?access_token=${token}`,
  fetchFullDoctorNotesByPatientId: (patientId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/patient/${patientId}/notes?access_token=${token}`,
  updateDoctorNote: (noteId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/${noteId}?access_token=${token}`,
  createDoctorNoteByAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/appointments/${appointmentId}/notes?access_token=${token}`,
  createDoctorNoteByPatientId: (patientId, token) =>
    `${STABLE_API_ROOT}/appointmentNotes/patient/${patientId}/notes?access_token=${token}`,
  createScheduledAppointmentDoctorRequest: (token) =>
    `${STABLE_API_ROOT}/appointments/createScheduledAppointmentDoctorRequest?access_token=${token}`,
  getDoctorAppointmentsByDate: (userId, date, token) =>
    `${STABLE_API_ROOT}/users/${userId}/appointments/date/${date}?access_token=${token}`,
  registerFeedBack: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/registerFeedback?access_token=${token}`,
  acceptAppointmentDate: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/accept-date?access_token=${token}`,
  requestNewDates: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/request-new-dates?access_token=${token}`,
  logout: `${STABLE_API_ROOT}/users/logout`,
  requestMessages: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chats?accessToken=${token}`,
  createChat: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chat`,
  getChatTranscripts: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/chat-transcripts?accessToken=${token}`,
  endAppointment: (channel, token) =>
    `${STABLE_API_ROOT}/appointments/${channel}/end?accessToken=${token}`,
  companyFindBySlug: (slug) => `${STABLE_API_ROOT}/companies/slug/${slug}`,
  editSpecialtyAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/editSpecialtyAppointment?accessToken=${token}`,
  getChimeMeeting: () => `${STABLE_API_ROOT}/services/create-chime-meeting`,
  getChimeAttendee: () => `${STABLE_API_ROOT}/services/create-chime-attendee`,
  enterChimeMeeting: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/enterChimeMeeting?accessToken=${token}`,
  kidsPricing: `${STABLE_API_ROOT}/services/kids-pricing`,

  familyMembers: (userId, token) =>
    `${STABLE_API_ROOT}/familyMembers/users/${userId}?accessToken=${token}`,
  familyMember: (memberId) => `${STABLE_API_ROOT}/familyMembers/${memberId}`,
  familyMemberWithId: (memberId) =>
    `${STABLE_API_ROOT}/familyMembers/familyMember/withId/${memberId}`,
  leaveMeeting: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/leaveMeeting?accessToken=${token}`,
  joinAppointmentNew: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/joinNew?accessToken=${token}`,
  sendPatientJoiningNotification: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/sendPatientJoiningNotification?accessToken=${token}`,
  getFullUrl: (nano) => `${STABLE_API_ROOT}/shortLinks/long-url/${nano}`,
  getLatestPosts: () => `${STABLE_API_ROOT}/services/latestBlogPosts`,
  getAllBlogPosts: (filter) =>
    `${STABLE_API_ROOT}/blogPosts/all${filter ? filter : ""}`,
  getPublishedPosts: (filter) =>
    `${STABLE_API_ROOT}/blogPosts${filter ? filter : ""}`,
  getBlogPostBySlug: (slug) => `${STABLE_API_ROOT}/blogPosts/slug/${slug}`,
  logView: (slug) => `${STABLE_API_ROOT}/blogPosts/log-view/${slug}`,
  getAllBlogCategories: `${STABLE_API_ROOT}/blogPosts/categories`,
  getAllBlogTags: `${STABLE_API_ROOT}/blogPosts/tags`,
  getAllBlogAuthors: `${STABLE_API_ROOT}/blogPosts/authors`,
  createBlogPost: (token) => `${STABLE_API_ROOT}/blogPosts`,
  editBlogPost: (token, slug) => `${STABLE_API_ROOT}/blogPosts/${slug}`,
  deleteBlogPost: (id) => `${STABLE_API_ROOT}/blogPosts/${id}`,
  uploadBlogImages: () => `${STABLE_API_ROOT}/blogPosts/upload-images`,
  uploadBlogImagesContent: () =>
    `${STABLE_API_ROOT}/blogPosts/upload-images-content`,
  getGoogleReviews: () => `${STABLE_API_ROOT}/services/googleReviews`,
  homePageDetails: () => `${STABLE_API_ROOT}/services/homePageDetails`,
  latestFaqs: () => `${STABLE_API_ROOT}/services/latestFaqs`,
  specialistsHomePage: () => `${STABLE_API_ROOT}/services/specialistsHomePage`,
  specialtiesHomePage: () => `${STABLE_API_ROOT}/services/specialtiesHomePage`,
  getEmergencyAvailableSlots: (doctorId, date) =>
    `${STABLE_API_ROOT}/services/availability-calendar/${doctorId}/date/${date}`,
  createScheduledEmergencyAppointment: () =>
    `${STABLE_API_ROOT}/appointments/createScheduledEmergencyAppointment`,
  sendWaitingPatientNotifications: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/sendWaitingPatientNotifications/${appointmentId}?accessToken=${token}`,
  runningLate: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/runningLate/${appointmentId}?accessToken=${token}`,
  fetchAppointmentPublicInfo: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/fetchAppointmentPublicInfo/${appointmentId}?accessToken=${token}`,
  generatePostConsultLink: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/generatePostConsultLink/${appointmentId}?accessToken=${token}`,
  changeDateWithEmergencySlot: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/changeDateWithEmergencySlot/${appointmentId}?accessToken=${token}`,
  sendAppointmentLinks: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/sendAppointmentLinks/${appointmentId}?accessToken=${token}`,
  appointmentParticipants: () => `${STABLE_API_ROOT}/participants/`,
  getAppointmentParticipant: (id) => `${STABLE_API_ROOT}/participants/${id}`,
  getAppointmentParticipants: (appointmentId) =>
    `${STABLE_API_ROOT}/participants/appointment/${appointmentId}`,
  reactivateAppointment: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/reactivateAppointment/${appointmentId}?accessToken=${token}`,
  offerReferral: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/offerReferral?accessToken=${token}`,
  responseReferralOffer: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/responseReferralOffer?accessToken=${token}`,
  referralAcces: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/referralAcces?accessToken=${token}`,
  amendAppointmentRequest: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/amend?accessToken=${token}`,
  createOfflineAppointment: (token) =>
    `${STABLE_API_ROOT}/appointments/createOfflineAppointment?accessToken=${token}`,
  getUserById: (userId, token) =>
    `${STABLE_API_ROOT}/users/getUserById/${userId}?accessToken=${token}`,
  createCallBackAppointment: (token) =>
    `${STABLE_API_ROOT}/appointments/createCallBackAppointment?accessToken=${token}`,
  markAsCompleted: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/markAsCompleted?accessToken=${token}`,
  sendDocumentEmail: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/sendDocumentEmail?accessToken=${token}`,
  fetchFollowUpInfo: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/followUpInfo?accessToken=${token}`,
  letAdminManageFollowUp: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/letAdminManageFollowUp?accessToken=${token}`,
  remindDoctorFollowUp: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/remindDoctorFollowUp?accessToken=${token}`,
  fetchDoctorInvoiceInfo: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/fetchDoctorInvoiceInfo?accessToken=${token}`,
  generateDoctorInvoice: (id, token) =>
    `${STABLE_API_ROOT}/appointments/${id}/generateDoctorInvoice?accessToken=${token}`,
  prevAppointmentInfo: (patientId, token) =>
    `${STABLE_API_ROOT}/appointments/prevAppointmentInfo/users/${patientId}?accessToken=${token}`,
  sendMedicareInvoice: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/sendMedicareInvoice/${appointmentId}?accessToken=${token}`,
  sendReferralByDoctor: (token) =>
    `${STABLE_API_ROOT}/appointments/sendReferralByDoctor?accessToken=${token}`,
  getReferralInfoWithAppointmentId: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/getReferralInfoWithAppointmentId/${appointmentId}?accessToken=${token}`,
  createReminder: (token) =>
    `${STABLE_API_ROOT}/reminders/createReminder?accessToken=${token}`,
  getChatToken: `${STABLE_API_ROOT}/services/chatToken`,
  renameReferralFile: (token) =>
    `${STABLE_API_ROOT}/fileUploads/renameReferralFile?accessToken=${token}`,
  createDiscount: () => `${STABLE_API_ROOT}/discountCodes/create`,
  verifyDiscountCode: (code) =>
    `${STABLE_API_ROOT}/discountCodes/verifyCode?code=${code}`,
  getDiscountCodes: (filter) =>
    `${STABLE_API_ROOT}/discountCodes/listDiscountCodes?${filter}`,
  getAvailabilityForUser: (userId) =>
    `${STABLE_API_ROOT}/availability/list/${userId}`,
  availabilitySettingsForUser: (userId) =>
    `${STABLE_API_ROOT}/users/${userId}/getConsultSettings`,
  updateConsultSettings: (userId) =>
    `${STABLE_API_ROOT}/users/${userId}/updateConsultSettings`,
  availability: (id) => `${STABLE_API_ROOT}/availability/${id}`,
  createAvailability: () => `${STABLE_API_ROOT}/availability/create`,
  updateAvailability: () => `${STABLE_API_ROOT}/availability/update`,
  getEmergencyPhoneSettings: (type, token) =>
    `${STABLE_API_ROOT}/twilioCalls/emergencyPhoneSettings/${type}?accessToken=${token}`,
  forwardChatToDoctor: (id, token) =>
    `${STABLE_API_ROOT}/appointments/chat/${id}/forward?accessToken=${token}`,
  getConsultHistory: (appointmentId, token) =>
    `${STABLE_API_ROOT}/appointments/consultHistory/${appointmentId}?accessToken=${token}`,
  getDoctorAvailableSlots: (doctorId, dateFrom, times, duration, showAll) =>
    `${STABLE_API_ROOT}/users/${doctorId}/getDoctorAvailableSlots?date=${dateFrom}&times=${times}&duration=${duration}&t=${new Date().getTime()}&showAll=${showAll}`,
  getAppointmentAvailableSlots: (token, appointmentId, dateFrom, times) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/availableSlots?accessToken=${token}&date=${dateFrom}&times=${times}&t=${new Date().getTime()}`,
  bookSpecialtySlot: (token, appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/bookSpecialtySlot?accessToken=${token}`,
  confirmSpecialtySlot: (token, appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/confirmSpecialtySlot?accessToken=${token}`,
  createScheduledFollowUpRequest: (token, appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/createScheduledFollowUpRequest?accessToken=${token}`,
  changeSpecialist: (token, appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/changeSpecialist?accessToken=${token}`,
  updateIcs: (doctorId) =>
    `${STABLE_API_ROOT}/availability/updateIcs/${doctorId}`,
  adminEdit: (id) => `${STABLE_API_ROOT}/appointments/${id}/adminEdit`,
  submitIhi: (id) => `${STABLE_API_ROOT}/appointments/${id}/submit/ihi`,
  isAppointmentDateAvailable: (appointmentId, query) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/isAppointmentDateAvailable?${query}`,
  deleteChat: (id) => `${STABLE_API_ROOT}/appointmentChats/${id}`,
  searchToAllocateFax: (search) =>
    `${STABLE_API_ROOT}/appointments/searchToAllocateFax?search=${search}`,
  waitingFurtherInformation: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/waitingFurtherInformation`,
  sendPatientData: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/sendFullPatientData`,
  emergencyDatesOffers: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/emergencyDateOffers`,
  destroyDateOffer: (id) => `${STABLE_API_ROOT}/dateOffers/${id}`,
  declineEmergencyOffer: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/declineEmergencyOffer`,
  dateOffersAvailableSlots: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/dateOffersAvailableSlots`,
  sendEmergencyRequestOffer: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/sendEmergencyRequestOffer`,
  sendPinCodeChangeEmail: () =>
    `${STABLE_API_ROOT}/verificationPhoneCodes/sendPinCodeChangeEmail`,
  changeEmail: (userId) => `${STABLE_API_ROOT}/users/changeEmail/${userId}`,
  createInstantAppointment: () =>
    `${STABLE_API_ROOT}/appointments/createInstantAppointment`,
  getInstantDoctorAppointmentSheets: (format) =>
    `${STABLE_API_ROOT}/appointments/instantDoctor/${format}`,
  instantDoctors: `${STABLE_API_ROOT}/users/instantDoctors`,
  instantDoctor: (id) => `${STABLE_API_ROOT}/users/instantDoctors/${id}`,
  createInstantDoctor: () => `${STABLE_API_ROOT}/users/instantDoctors`,
  sendIhiInstructions: () =>
    `${STABLE_API_ROOT}/appointments/sendIhiInstructions`,
  getPrescriptions: (appointmentId) =>
    `${STABLE_API_ROOT}/appointments/${appointmentId}/prescriptions`,
};
